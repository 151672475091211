import React from "react";
import { Link } from "gatsby";

import Container from "components/Container";

const Header = ({pageName}) => {
  // console.log(pageName)
  return (
    <header>
      <Container>
        <Link to='/'>Antoine Venco</Link>
        <Link to={pageName === "about" ? '/' : '/about/'}>{pageName === "about" ? "Close" :"About"  }</Link>
      </Container>
    </header>
  );
};

export default Header;



