import * as React from "react";
import { motion } from "framer-motion";

const animLetter = {
  hidden: { y: 200 },
  visible: { y: 0 }
};

const animSeparator = {
  hidden: { x: "-100%" },
  visible: { x: 0 }
};

const AnimatedText = ({ children, small, delay }) => {
  return (
    <motion.div
      style={{ fontSize: "16px" }}
      className='text-wrapper'
      style={{
        position: small ? "static" : null,
        marginBottom: small ? "-120px" : null
      }}
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={{
        visible: {
          transition: {
            delayChildren: delay,
            staggerChildren: small ? 0.01 : 0.03
          }
        }
      }}
    >
      {/* <motion.p
        variants={animLetter}
        transition={{ type: "spring", stiffness: 100, damping: 1000, mass: 5 }}
      >
        {children}
      </motion.p> */}

      {children.split(" ").map((word, wordIndex) => {
        return (
          <span key={wordIndex} className='word-wrap'>
            {word.split("").map((letter, letterIndex) => {
              return (
                <motion.span
                  key={letterIndex}
                  variants={animLetter}
                  style={{
                    originY: 0,
                    fontSize: small ? "48px" : null,
                    lineHeight: small ? "54px" : null,
                    textTransform: small ? null : "uppercase",
                    margin: small ? "24px 0" : null
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 10,
                    damping: 100,
                    mass: 0.2
                  }}
                  s
                >
                  {letter}
                </motion.span>
              );
            })}
            &nbsp;
          </span>
        );
      })}

      <div className='separator-wrapper'>
        <motion.div
          // variants={animSeparator}
          className='separator'
          transition={{
            delay: children.length / 10,
            type: "spring",
            stiffness: 100,
            damping: 100,
            mass: 1
          }}
        />
      </div>
    </motion.div>
  );
};

export default AnimatedText;
