import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { motion, MagicMotion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

import "assets/stylesheets/application.scss";

import Header from "components/Header";
import Footer from "components/Footer";

const Layout = ({ children, pageName }) => {
  let className = "";

  if (pageName) {
    className = `${className} page-${pageName}`;
  }

  return (
    <div>
      <Helmet bodyAttributes={{ class: className }}>
        <title>Gatsby Site</title>
      </Helmet>
      
      <div className='wrapper'>
        <Header pageName={pageName} />
        <AnimatePresence exitBeforeEnter>

          <motion.main
            initial={{ y: pageName === "home" ? 0 : 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -200, opacity: 0 }}
            transition={{
              type: "spring",
              damping: 200,
              stiffness: 300,
              mass: 20
            }}
          >
            <AnimateSharedLayout>
            	{children}
            </AnimateSharedLayout>
          </motion.main>
        </AnimatePresence>

        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
